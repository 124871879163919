.ui.card.card--sponsor {
  max-width: 270px;

  .logo {
    display: block;
    width: 100%;
    height: 90px;
    object-fit: contain;
  }

  .trophy--platinum {
    background-color: #2185d0;
    border-color: #2185d0;
    color: #fff;
  }

  .trophy--gold {
    background-color: #fbbd08;
    border-color: #fbbd08;
    color: #fff;
  }

  .trophy--silver {
    background-color: #767676;
    border-color: #767676;
    color: #fff;
  }
}

@media (max-width: 768px) {

  .ui.card.card--sponsor {
    max-width: 100%;
    width: 100%;
  }
}