@import "../../../styles/_shadow.scss";
@import "./actions.scss";

@import "./_mixins.scss";

.Contacts--variant-badge {
    @include grid(4, 1em);
}

.Contact--variant-badge {
    @include shadow(1);

    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: #fefefe;
    border-radius: 2px;
    overflow: hidden;

    .Contact {
        &__Wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 1em;
            height: 100%;
            position: relative;
        }
        &__Profile {
            display: flex;
            flex-direction: column;
        }
        &__Thumbnail {
            width: 100%;
        }
    }

    .ItemInfos {
        padding: 1.5em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .ItemInfo {
        &--title {
            font-size: 1.5em;
            font-weight: bold;
            line-height: 1;
            margin-bottom: 0.25em;
        }
        &--subtitle {
            opacity: 0.6;
        }
    }

    @include Actions--icon-text;
    @include Actions--hover-background(#f0f0f0);
}
