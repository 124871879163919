.workshops--container {
    &.vertical {
        .category {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .ui.header {
                margin-top: 4px;
                margin-bottom: 10px;
            }
        }

        .workshop--clickable--card {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

.ui.divider.horizontal {
    .ui.button {
        height: 30px;
        width: 30px;
        align-items: center;
        justify-content: center;
        display: flex;
    }
}

@media (max-width: 768px) {
    .ui.grid.workshops--container {
        &.vertical {
            .column {
                width: 100%;
            }

        }
    }
}