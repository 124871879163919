.details {
    &__container {
        position: relative;
        display: flex;
        align-items: center;

        .logo {
            margin-right: 25px;
            padding: 8px;
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.hasBanner {
            display: block;

            .banner {
                width: 100%;
                padding-top: 30%;
                background-position: center;
                background-size: cover;
            }

            .logo {
                position: absolute;
                left: 30px;
                border: 1px solid rgba(0, 0, 0, 0.2);
                background: white;
                padding: 4px;
                border-radius: 4px;
                bottom: -40px;
            }
        }
    }

}

@media (min-width: 769px) {
    .page.page--exhibitor {
        .go-back {
            display: inline-block;
            cursor: pointer;
            margin-bottom: 8px;
        }
    }
}

@media (max-width: 768px) {
    .page.page--exhibitor {

        .go-back {
            margin-top: 20px;
            font-size: 1.2em;
            margin-bottom: 8px;
        }

        .details {
            &__container {
                display: block !important;

                .logo {
                    margin-bottom: 10px;
                }
            }
        }

    }
}