.AgendaWeek {
  .AgendaDay {
    &--mode-variable-height {
      .AgendaSession {
        margin-top: 0;
        margin-bottom: 0;

        &__content {
          position: relative;
        }

        &__title {
          line-height: initial;
        }

        &__location {
          position: absolute;
          top: 2px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 11px;

          i {
            margin-right: 4px;
          }

          @media print {
            display: none;
          }
        }
      }


      .AgendaSession__wait-time {
        min-height: 40px;

        @media print {
          min-height: 20px;
        }

        &.AgendaSession__wait-time--minutes-0 {
          min-height: 10px;
        }
      }

      .AgendaSession__content {
        margin-left: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media print {
          padding: 0.5em;
        }
      }

      .AgendaSession__content>.AgendaSession__time {
        display: none;
      }

      .AgendaSession--minutes {
        @for $i from 1 through 12 {
          &-#{$i * 15} {
            .AgendaSession__content {
              min-height: max(60px, (40px + $i * 10px));

              @media print {
                min-height: max(40px, (20px + $i * 6px));
              }
            }
          }
        }
      }
    }
  }
}