.SquareSpeakerCard {

  background: none !important;
  box-shadow: none !important;
  border: none !important;


  &:hover {
    transform: none !important;

  }

  &__avatar {
    width: 100%;
    border: 1.5px solid rgba(34, 36, 38, .1);
    display: block;
    aspect-ratio: 1;
  }

  &__content {
    flex-grow: 0 !important;
    border-top: none !important;
    padding: 10px 0 0 0 !important;
    display: flex;
    align-items: flex-start;

  }

  &__logo {
    background-color: white;
    margin-right: 10px;
    box-shadow: 1px 1px 3px #00000029;
    border-radius: 30px;
  }

  &__firstNameLastName {
    text-align: left;
    font-size: 18px !important;
    letter-spacing: 0;
    color: #0050D7 !important;
  }

  &__companyRole {
    color: #4D5592 !important;
    font-size: 13px !important;
    margin-top: 0 !important;
    margin-bottom: 7px;
  }
}