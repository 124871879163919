.CloudTvLiveDescription {
    margin-top: 14px;
    position: relative;

    .icon.times {
        position: absolute;
        right: 0px;
        top: 0px;
        font-size: 1.2em;
    }

    .logos {
        img {
            background-color: white;
            border-radius: 50%;
            box-shadow: 1px 1px 3px #00000029;
            margin-right: 5px;
        }
    }

    .speakers {
        display: flex;
        align-items: center;

        .avatars {
            margin-left: 10px;
        }
    }

    .title {
        font-size: 1.5em !important;
        margin-top: 8px !important;
    }
}