.RoomCard {
    &.ui.card {
        border-radius: 18px;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;

        .content {
            padding: 2em;
        }
    }

    &__users {
        margin-top: 20px;
        display: block;

        .count {
            display: flex;
            align-items: center;
            color: rgba(0, 0, 0, 0.6);
            font-size: 12px;
            margin-bottom: 10px;

            img {
                height: 16px;
                margin-left: 4px;
                margin-right: 12px;
            }

            .guests {
                line-height: 12px;
            }
        }

        .ui.label {
            color: black !important;
            border-radius: 20px;
            border: none;
            padding: 10px 16px;
        }

    }

    &__actions {
        display: flex;

        .ui.button {
            flex: 1;
            margin: 0px;
            border-radius: 0px;
            min-height: 44px !important;

            &.accept {
                color: #59B655 !important;
                background-color: #C7FFC5 !important;
                border-bottom-right-radius: 18px;
            }

            &.refuse {
                color: #C93B33 !important;
                background-color: #FFB2AD !important;
                border-bottom-left-radius: 18px;
            }
        }
    }
}