.FaqBlock {
  .Collapsible {
    border-top: 1px solid #ddd;
  }

  &__question {
    padding: 1em;
    display: flex;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:focus {
      outline: solid;
    }

    .title {
      flex: 1;
      font-weight: bold;
      text-align: left;
      padding: initial;
      background: initial;
      border: initial;
      cursor: pointer;
    }
  }

  &__answer {
    padding: 1em;
    padding-top: 0em;
  }
}