.ProgramBlock {
    margin-top: 20px;

    .Collapsible {
        width: 100%;
        background-color: white;
        padding: 24px 0px;
        margin-bottom: 20px;
        border-top: 8px transparent solid;

        &__trigger {
            cursor: pointer;
        }
    }

    &__day {
        display: flex;
        justify-content: center;
        align-items: center;

        .side {
            margin: 0px;
            transform: rotate(-90deg);
            text-transform: uppercase;
            font-weight: normal;
            width: 70px;
            text-align: center;
        }

        h3.ui.header.date {
            flex: 1;
            text-transform: uppercase;
            margin: 0px;
            padding-left: 1em;
        }

        i.chevron {
            border: 2px solid #666;
            border-radius: 20px;
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0px;
            margin-right: 20px;
            line-height: 1;
        }
    }

    &__details {
        padding: 40px 70px 20px;
        display: flex;
        justify-content: center;

        .items {
            flex: 1;
            padding-right: 30px;

            .item {
                padding-top: 1em;
                padding-bottom: 1em;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #A8A5A5;

                &:last-child {
                    border-bottom: none;
                }

                .ui.image.icon {
                    padding-left: 0px;
                    width: max-content;
                    flex: unset;
                    margin-right: 10px;
                }

                i.icon {
                    font-size: 2em;
                }

                .content {
                    flex: 1;

                    h4.ui.header.title {
                        margin-top: 0px;
                        margin-bottom: 0px;
                    }

                    .subtitle {
                        color: #A8A5A5;
                    }
                }

            }

        }

        .image {
            padding-left: 30px;
            flex: 1;
        }
    }
}

@media (max-width: 480px) {
    .ProgramBlock {

        &__day {
            .side {
                width: 60px;
            }
        }

        &__details {
            padding: 40px 60px 20px;
        }

        .items {
            padding-right: 0px;
        }

        .image {
            display: none;
        }
    }
}