.ui.table.table--profile {
  tr td:first-child:not(.ignored) {
    font-weight: 700;
    color: rgba(0,0,0,.95);
  }
}

.profile--block {
  .ui.container{
    margin-bottom: 2em;
  }
}

@media (max-width: 768px) {
  .ui.table.hide-mobile-header {
    >thead {
      display: none;
    }
  }
}