.sso-authentication {

  .ui.button.sso--button {

    margin-top: 15px;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


  .ui.horizontal.divider {
    padding-top: 15px;
    padding-bottom: 15px;
    color: rgb(203, 212, 219);
  }
}
