.SidebarMenuBlock__Item {
  border-left: 4px solid transparent;
  padding: 0.75em 1.5em;
  cursor: pointer;
  color: initial;
  display: block;

  &:hover {
    color: initial;
  }

  &.is-active {
    border-left-color: #777;
    font-weight: bold;
  }
}
