@import "../../../styles/_shadow.scss";
@import "./actions.scss";

@import "./_mixins.scss";

.Contacts--variant-default,
.Contacts--variant-compact {
    @include grid(2, 1em);
}

.Contact--variant-default,
.Contact--variant-compact {
    background: #fefefe;
    @include shadow(1);
    border-radius: 2px;

    .Contact {
        &__Wrapper {
            display: flex;
            justify-content: space-between;
            height: 100%;
        }
        &__Profile {
            display: flex;
            flex-direction: row;
        }
    }
    .ItemInfos {
        padding: 0.5em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: initial;
    }
    .ItemInfo {
        &--subtitle {
            opacity: 0.6;
        }
    }
    @include Actions--square-buttons(3em);
}

.Contact--variant-compact {
    .Contact {
        &__Thumbnail {
            padding: 0.5em;
            padding-right: 0;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            & img {
                border-radius: 50%;
            }
        }
    }
    .ItemInfo {
        &--subtitle {
            font-size: 0.9em;
        }
    }
}

.Contact--variant-default {
    .Contact {
        &__Thumbnail {
            width: 4.5em;
            min-height: 6em;
            height: 100%;
            flex-shrink: 0;

            > img {
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .ItemInfos {
        margin-left: 1em;
    }
    .ItemInfo {
        &--title {
            font-size: 1.5em;
            line-height: 1;
        }
    }
    .Action {
        width: 4.5em;
        &__Icon {
            font-size: 1.5em;
        }
    }
}
