.secondary-menu {
  &__container {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    width: 100%;
    height: 60px;
  }

  &__logoAndPageName {
    display: flex;
    align-items: center;
    justify-content: center;

    .home {
      margin-right: 20px;
    }
  }

  &__item--profile {
    .lastName {
      margin-left: 0.3em;
    }
  }
}


.item--languages {
  &-horizontal {
    span {
      &:not(:last-child):after {
        content: "/";
      }
    }

    a {
      text-transform: uppercase;
      padding: 0.5em 0.5em;
    }
  }
}
