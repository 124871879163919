.clickable-svg {
  .clickable-item {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}
