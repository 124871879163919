.block--cms-files {
  .ui.card {
    width: 260px;

    > .content {
      &.content--image {
        background-color: #fafafa;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
      }

      > .header.break-all {
        word-break: break-all;
      }

      > .header:not(.ui) {
        font-size: 1.1em;
      }
    }
  }
}
