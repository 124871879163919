.ui.list > .item.item--table {
  .content {
    &--action {
      width: 40px;
      cursor: pointer;
      opacity: 0.7;

      .icon {
        color: rgba(0, 0, 0, 0.6);
      }

      &:hover {
        .icon {
          color: rgba(0, 0, 0, 1);
        }
      }
    }
  }
}
