.Step {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 64px;
  width: 32px;

  &__container {
    display: flex;
    flex-direction: row;

    .Step__child {
      padding: 0.5em;

      &.disabled, &.disabled .ui.button.link {
        color: rgba(32, 32, 32, 0.4);
        font-size: 0.9rem;
        padding-top: 0.75em;
        line-height: 1.4285em;
      }
    }
  }

  &__line {
    width: 1px;
    background: rgba(128, 128, 128, 0.1);
    min-height: 1em;

    opacity: 0;
    &.show {
      opacity: 1;
    }

    &:last-child {
      flex: 1;
    }
  }

  &__step {
    border-radius: 64px;
    width: 9px;
    height: 9px;
    text-align: center;
    background-color: rgb(192, 192, 192);
    color: rgb(32, 32, 32);

    i.icon {
      margin-right: 0;
    }

    &.active,
    &.success {
      color: white;
      width: 21px;
      height: 21px;
      margin-top: -6px;
      margin-bottom: -6px;
      line-height: 21px;
      font-size: 12px;
    }
    &.active {
      background-color: rgb(64, 64, 64);
    }
    &.success {
      background-color: #16ab39;
    }
  }
}
