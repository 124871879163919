#root > .page.page--exhibitors,
#root > .page.page--exhibitor {
  flex: 1;
  display: flex;
  flex-direction: column;

  .page--cms-container {
    flex: 1;
  }
}
