$menu-size: 92px;
$left-margin: 80px;
$left-margin-small: 54px;

.page.page--verticalMenu {
  @media only screen and (min-width: 768px) {
    padding-left: $menu-size;
  }

  > .page--cms-container > .content > .ui.container {
  }

  > .page--cms-container > .content > .ui.container {
    // Phone
    @media only screen and (max-width: 767px) {
      // Normal styles
    }

    // Tablet / Desktop
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      margin-left: $left-margin-small !important;
      width: 768px - $menu-size - $left-margin-small * 2;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      margin-left: $left-margin-small !important;
      width: 992px - $menu-size - $left-margin-small * 2;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1126px + $menu-size + $left-margin*2) {
      margin-left: $left-margin !important;
      width: 1200px - $menu-size - $left-margin * 2;
    }
    @media only screen and (min-width: 1127px + $menu-size + $left-margin*2) {
      margin-left: $left-margin !important;
      width: 1127px;
    }
  }

  .page__header {
    margin-top: 2em;
    margin-bottom: 4em;

    &.ui.container {
      margin-right: 2em !important;
    }
    position: relative;

    .item {
      color: white;
    }

    .button--avatar {
      background: white;
      color: black;
    }
  }

  .ui.menu .button--avatar {
    margin-right: 1em;
  }
}

.menu-wrapper {
  &--leftSidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: $menu-size;
    overflow: hidden;

    > .ui.menu.vertical {
      height: 100%;
    }
  }
}

.ui.menu.vertical {
  &.menu--main {
    font-size: 0.85rem;
    border: 0;
    border-radius: 0;
  }

  .item:before {
    display: none;
  }

  .item.item--logo {
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 2em;
    padding-bottom: 2em;
  }
}
