.UserProfile {
  &__qrcode {
    text-align: center;

    @media (max-width: 768px) {
      &.ui.segment {
        margin: 0 auto !important;
        max-width: 400px;
      }
    }
  }
}

@media (max-width: 768px) {
  .ui.grid.profile--block .user-profile.row {
    display: flex;
    flex-direction: column;
  }
  .UserProfile {
    &__column {
      &--qrcode {
        order: 0;
      }
      &--info {
        order: 1;
      }
      &--avatar {
        order: 2;
      }
    }
  }
}
