.ui.card.notification {
  width: auto !important;
  box-shadow: none;
  border-radius: 0;
  box-sizing: border-box;


  .content {
    padding: 0.7em 1.5em;

    .type {
      font-style: italic;
      text-transform: uppercase;
      font-size: 0.7em;
      margin-bottom: 1.1em;
    }

    .date {
      margin-top: 0.2em;
      font-size: 0.7em;
      font-style: italic;
    }
  }
}


.ui.card.notification:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  left: -1px;
  z-index: -1;
}
