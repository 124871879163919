.fullscreen-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  @media (max-width: 480px) {
    max-width: 100%;
  }

  >.fullscreen-center-wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;

    >.ui.grid>.column.column--auth-content {
      max-width: 450px;

      @media (max-width: 480px) {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .fullscreen-container {
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .fullscreen-container {
    .ui.grid {
      display: flex;
    }
  }
}

@media (max-width: 480px) {
  .fullscreen-container {
    padding: 5px;
    display: block;
    overflow-x: hidden;

    &>.grid>.column {
      margin-top: 8px;
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}