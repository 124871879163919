body ::-webkit-scrollbar-track {
    background: none;
}

.gm-style-iw-d div {
    background-color: initial !important;
}

.gm-style-iw-t button.gm-ui-hover-effect {
    display: none !important;
}

.workshops-map {
    display: flex;
    flex-grow: 1;
    height: 500px;
    max-height: 80vh;
    &__sidebar {
        overflow: hidden;
        width: 100%;
        max-width: 230px;
        // box-shadow: inset -40px 0px 20px 0 #f0f0f0;
        background: #fff;
        & .ui.push.left.visible.sidebar {
            box-shadow: inset -5px 0px 10px 0 #e0e0e0;
            overflow-x: hidden;
            width: 100%;
            background: transparent !important;
        }
        &__content {
            overflow-y: scroll;
            width: 100%;
            max-width: 100%;
            padding: 1em 0.5em;
            & .sidebar-selector {
                font-size: 1em;
                width: 100%;
                background: none;
                padding: 5px 0;
                margin: 0;
                border: 0px solid #404040;
                text-align: left;
                font-size: 16px;
                cursor: pointer;
                &--active {
                    font-weight: bold;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .workshops-map {
        flex-direction: column;
        height: 1000px;
        max-height: 90vh;
        &__sidebar {
            max-height: 40vh;
            max-width: 100%;
        }
    }
}

.info-window {
    width: 200px;
    max-width: 200px;
    h2.ui.header {
        font-size: 1.4em;
    }
    .ui.header {
        font-size: 18px;
    }

    .ui.button.close {
        text-align: right;
        float: right;
        background-color: rgba(0, 0, 0, 0);
        color: #000;
        padding: 0;
        position: absolute;
        right: 0;
        top: 5px;
    }
    .info {
        .label {
            font-weight: bold;
            margin: 0;
            padding-bottom: 0.25em;
        }
        .content {
        }
    }
}
