@import "../../../styles/_shadow.scss";
@import "./actions.scss";
@import "./_mixins.scss";

.Contacts--variant-material,
.Contacts--variant-material-high {
  @include grid(2, 1em);
}

.Contact--variant-material,
.Contact--variant-material-high {
  background: #fefefe;
  @include shadow(1);
  border-radius: 8px;

  .ItemInfo {
    margin: 0.125em 0;
    &--title {
      font-size: 1.35em;
    }
    &--subtitle {
      color: #777;
    }
  }

  @include Actions--material-buttons;
}

.Contact--variant-material {
  .Contact {
    &__Thumbnail {
      padding: 0.5em;
      width: 4em;
      display: flex;
      justify-content: center;
      align-items: center;
      & img {
        border-radius: 50%;
      }
    }
    &__Wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    &__Profile {
      display: flex;
      flex-direction: row-reverse;
      padding: 1em;
    }
  }
  .ItemInfos {
    padding: 0.5em;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: initial;
  }
}

.Contact--variant-material-high {
  .Contact {
    &__Thumbnail {
      padding: 1em;
      box-sizing: content-box;
      width: 10em;
      max-width: 50%;

      display: flex;
      justify-content: center;
      align-items: center;
      & img {
        border-radius: 50%;
      }
    }
    &__Wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    &__Profile {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1em;
    }
  }
  .ItemInfos {
    padding: 0.5em;
    justify-content: center;
    line-height: initial;
    text-align: center;
  }
}
