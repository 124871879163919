.ui.menu {
    & .menu--main {

        &__left {
            flex: 1;

            &.centered {
                justify-content: flex-end;
            }
        }

        &__center {
            margin: auto;
        }

        &__right {
            justify-content: flex-end;

            &.hasRight {
                flex: 1;
            }

            &.centered {
                justify-content: flex-start;
            }

            .item.right {
                margin-left: initial !important;
            }
        }

    }
}