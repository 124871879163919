.WorkshopIframeRegistration {
    text-align: center;

    &__register {

        &.ui.button {
            height: 36px;
            font-size: 1em;
            font-weight: normal;
            margin: 20px;
            text-transform: uppercase;
        }
    }

    &__registered {
        margin-top: 10px;

        .actions {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 10px;

            .ui.button.update {
                font-size: 1em;
                font-weight: normal;
            }

            .WorkshopAddToCalendarBlock--Dropdown {
                width: max-content !important;
                height: 36px;
                margin-right: 0px;
                display: flex;
                justify-content: center;
                align-items: center;

                .text.divider {
                    font-size: 1em !important;
                    margin-left: 6px;
                    font-weight: normal !important;
                }
            }
        }
    }

}