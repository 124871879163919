.BackToTopArrow {
  right: 11px;
  bottom: 11px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  z-index: 100;
  border-radius: 50%;
  background-color: #333;
  cursor: pointer;
  text-align: center;
  position: fixed;
  transition: transform 250ms ease;
  transform: translateY(80px);

  &.is-visible {
    transform: translateY(0);
  }

  i.icon {
    color: white;
    text-align: center;
    margin: auto;
    font-size: 1.5rem;
  }
}
