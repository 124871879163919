.page.page--workshop-live {

  &.headered {
    margin-top: 120px;

    >.page-background {
      top: 120px;
    }

    .menu--header {
      display: none;
    }
  }

  >.header--fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 120px;
    z-index: 100;
    text-align: center;

    .container {
      padding: 10px 0px;
      height: 120px;
      position: relative;

      .rightLogo {
        top: 10px;
        right: 30px;
        position: absolute;
      }
    }

  }

  .ui.grid.workshop--blocks {
    margin-top: -1rem;
  }

  .workshop--details {
    display: block;
    margin-top: 10px;

    &__title {
      font-size: 2em;
    }

    &__shortDescription {
      text-align: justify;
      font-size: 1.2em;
    }

    &__description {
      text-align: justify;
      font-size: 1.3em;
      margin-top: 10px;
    }
  }
}

@media (max-width: 768px) {
  .page.page--workshop-live {
    >.header--fixed {
      text-align: left;

      .container {
        display: flex;
        align-items: center;

        .logo {
          margin-left: 10px;
          max-width: 230px;
        }

        .rightLogo {
          right: 10px;
        }
      }
    }
  }
}