.CarouselBlock {
    *:focus {
        outline: none;
    }

    .slider {
        .slider-slide {
            outline: none;
            // display: flex!important;
        }

        .slider-control-centerright,
        .slider-control-centerleft {
            top: calc(50% - 20px) !important;
            font-size: 20px;
            cursor: pointer;

            i.icon.disabled {
                opacity: 0.25 !important;
            }
        }

        .slider-control-centerright {
            right: -30px !important;
        }

        .slider-control-centerleft {
            left: -30px !important;
        }
    }

    &__video {
        position: relative;

        .ui.card.card--video {
            background-color: transparent;
            border: none;
            box-shadow: none;
            width: 100%;

            .ui.embed {
                border-radius: 0px !important;
            }

            &:hover {
                transform: none;
            }
        }
    }

    &__text {
        position: relative;
        padding: 10px;

        .read-more {
            margin: 10px 0px;
            text-align: right !important;
            text-decoration: underline;
            font-weight: bold;
            cursor: pointer;
        }
    }

    &__document {
        .content {
            padding: 0.5em;
        }
    }
    &__image {
        height: 100%;
        img {
            height: 100%;
            object-fit: cover;
        }
    }
}

@media (max-width: 768px) {
    .CarouselBlock {
        .slider {
            .slider-control-centerright {
                right: 0px !important;
            }

            .slider-control-centerleft {
                left: 0px !important;
            }
        }
    }
}
