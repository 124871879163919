.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    // a param taille image 200px en inline style
    gap: 1em; // a param - none - small, medium, big (voir semantic ui)
    padding: 1em;// a param - none - small, medium, big (voir semantic ui)
    &__tile {
        padding-bottom: 100%;
        position: relative;
        cursor: pointer;
    }
    &__message {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
    }
    & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}