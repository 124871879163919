.WorkshopTable {
  &.ui.grid {
    margin: 20px 0px;
  }

  .ui.header.day {
    margin-bottom: 0px;
  }

  .ui.table {
    padding: 0px;
    background: transparent;
  }

  td.time {
    text-align: center;

    .choice-required {
      font-weight: normal;
      font-size: 0.85em;
      opacity: 0.8;
    }
  }

  &__workshops {
    cursor: pointer;
    padding: 0px;
    vertical-align: top;
  }

  &__workshop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px;

    .actions {
      min-width: 100px;
      max-width: 100px;
      margin-left: 10px;

      .ui.button {
        padding: 6px;
        margin: 4px 0px;
        width: 100%;
        font-weight: 300;
      }
    }
  }

  &__validation {
    .ui.container {
      padding: 1em 0em;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    .ui.text.header {
      margin-bottom: 0px;
    }
  }
}

@media (max-width: 768px) {
  .WorkshopTable {
    &__days {
      .chevron.down.icon {
        position: absolute;
        right: 4px;
      }
    }

    &__options {
      .ui.button {
        min-width: 100px;
      }
    }

    &__workshop {
      padding: 11px 0rem;
      align-items: center;
    }

    &__validation {
      .ui.container {
        display: block;
        padding: 0em;
        margin: 0px !important;
      }

      padding: 1em;

      text-align: center;

      .ui.header.text {
        margin-bottom: 10px;
      }
    }
  }

  .ui.modal .actions {
    margin: auto;
    text-align: center;

    >.button {
      margin-bottom: 0px !important;
    }
  }
}