@import "../../../styles/_shadow.scss";
@import "./actions.scss";
@import "./_mixins.scss";

.Contacts--variant-info-card {
    @include grid(2, 1em);
}

.Contact--variant-info-card {
    background: #fefefe;
    @include shadow(1);
    border-radius: 2px;

    .Contact {
        &__Thumbnail {
            padding: 0.5em;
            padding-left: 0;
            width: 4em;
            display: flex;
            justify-content: center;
            align-items: center;
            & img {
                border-radius: 50%;
            }
        }
        &__Wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding: 0.5em;
        }
        &__Profile {
            display: flex;
            flex-direction: row;
            padding: 1em;
            // margin: 1em 0;
        }
    }
    .ItemInfos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: initial;
    }
    .ItemInfo {
        &--title {
            font-size: 1.5em;
        }
        &--subtitle {
        }
    }

    @include Actions--item-list;
}
