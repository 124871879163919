.page-background {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &--video {
    background: #eee;
    height: 100%;
    overflow: hidden;
    padding: 0;
  }
  iframe, video, .page-background__wrapper--16_9 {
    border: 0;
    box-sizing: border-box;
    height: 56.25vw;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    width: 177.77777778vh;
  }
}
