.menu--filter {
  .item > .count {
    float: right;
  }
}

.ui.menu > .item > .ui.text.menu {
  margin-top: 0;
  margin-bottom: 0;
}
