@keyframes anim-tilted-menu__rotate {
    0% {
        opacity: 0;
        transform: rotate(90deg) scale(1.5);
    }
    50% {
        opacity: 1;
        transform: rotate(90deg) scale(1);
    }
    100% {
        opacity: 1;
    }
}

@keyframes anim-tilted-menu__item {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.TiltedMenu {
    width: 100%;
    padding-bottom: 56%;
    position: relative;
    overflow: hidden;
    &__container {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    &__rotating {
        width: 100%;
        transform: rotate(-30deg);
    }

    &__infos {
        white-space: nowrap;
        position: absolute;
        left: 50%;
        top: 50%;
        transform-origin: center;
        transform: translate(-50%, -50%) rotate(90deg);
        & p,
        & h1 {
            color: #fff;
            width: 100%;
            margin: 0;
            cursor: default;
        }
        & p {
            font-size: 0.8em;
            &:last-child {
                text-align: right;
            }
        }
        & h1 {
            font-size: 1.2em;
            text-transform: uppercase;
        }
    }

    & nav.menu {
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        gap: 2em;
    }

    &__links {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        justify-content: center;

        flex: 1;
        display: flex;

        &--side-left {
            text-align: right;
        }

        &--side-right {
            text-align: left;
        }

        li {
            line-height: 1em;
            padding: 0.1em 0;

            & a {
                color: #fff;
                text-transform: uppercase;
                text-decoration: none;
                font-size: 1em;
                font-weight: 500;
                word-break: break-all;
                opacity: 0.8;
                transition: 0.2s opacity;

                &.faded {
                    opacity: 0.4;
                }
                &.links__bold {
                    font-weight: bold;
                }
                &:hover {
                    opacity: 1;
                    color: #fff;
                    text-shadow: #fff 0 0 0.5em;
                }
            }
        }
    }

    &--animated {
        .TiltedMenu {
            &__rotating {
                width: 100%;
                transform: rotate(45deg);
                animation: anim-tilted-menu__rotate 2s;
            }
        }

        li {
            animation: anim-tilted-menu__item 0.5s;
            animation-fill-mode: backwards;

            @for $i from 0 through 10 {
                &[data-index="#{$i}"] {
                    animation-delay: 2s + $i * 0.15s;
                }
            }
        }
    }
}
