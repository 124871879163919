.UserInfo {
    margin: 20px 0;

    &__main {
        .fullname {
            white-space: pre-line;
        }
    }

    &__welcome {
        &.ui.header {
            margin-bottom: 10px;
        }
    }

    &__header {
        &.ui.header {
            margin-top: 0px;
        }
    }

    &__content {
        display: flex;
    }

    &__thumbnail img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 10px;
    }

    &__qrcode {
        text-align: center;
        max-width: 300px;

        @media (max-width: 768px) {
            &.ui.segment {
                margin: 1em auto;
                width: 90vw;
            }
        }
    }

    &__field {
        flex: 1;

        .field {
            width: 100%;

            .ui.fluid.input > input {
                width: 100% !important;
            }
        }
    }

    &__column {
        &.column {
            display: flex !important;
            align-items: center !important;
            padding-left: 0px !important;
            margin-bottom: 8px !important;
        }
    }

    &__profileSharing {
        &.ui.grid {
            flex: 1;
            margin-left: 20px !important;

            .row {
                padding-top: 0px;
                display: block;
            }
        }
    }
}

@media (max-width: 768px) {
    .UserInfo {
        &__avatar {
            margin: 0 auto;
        }

        &__main {
            margin-left: 0 !important;
        }

        &__main > h3 {
            text-align: center;
        }

        &__actions {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        }

        &__content {
            display: block;

            .ui.grid {
                .row.group {
                    padding-top: 0px;
                    display: block;
                }
            }
        }

        &__column {
            &.column {
                margin-top: 10px !important;
            }
        }

        .button--action {
            margin-bottom: 8px;
        }

        &__profileSharing {
            &.ui.grid {
                margin-left: 0px !important;
            }
        }
    }
}
