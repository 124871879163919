@mixin Actions--icon-text {
  .Action {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.25em 1em;
    height: 100%;

    &__Icon {
      color: #fff;
      border-radius: 50%;
      margin: 0 1em 0 0;
      width: 1.75em;
      height: 1.75em;
      line-height: 1.75em;
      flex-shrink: 0;

      & i {
        width: 100%;
        margin: auto;
      }
    }
    &__Label {
      display: none;
    }
  }
}

@mixin Actions--square-buttons($width) {
  .Actions {
    display: flex;
    height: 100%;
  }
  .Action {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: $width;
    color: #eee;
    &__Icon {
      font-size: $width * 0.3;
    }

    &:hover {
      opacity: 0.8;
    }

    &__Label,
    &__Value {
      display: none;
    }
  }
}

@mixin Actions--material-buttons {
  .Actions {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #dedede;
  }
  .Action {
    padding: 1em;
    width: 0;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &:hover {
      background: rgba(0, 0, 0, 0.02);
    }
    &:not(:last-child) {
      border-right: 1px solid #dedede;
    }
    &__Label {
      display: block;
      color: #333;
    }
    &__Value {
      display: none;
    }
    &__Icon {
      color: #777;
      padding-right: 0.5em;
    }
  }
}

@mixin Actions--item-list {
  .Actions {
    flex-direction: column;
  }
  .Action {
    padding: 1em;
    display: flex;
    flex-direction: column;
    &:hover {
      background: rgba(0, 0, 0, 0.02);
    }
    &:not(:last-child) {
      border-bottom: 1px solid #dedede;
    }
    &__Label,
    &__Value {
      display: block !important;
      line-height: 1.35em;
    }
    &__Label {
      opacity: 0.7;
    }
    &__Icon {
      text-align: right;
      height: 0;
      line-height: 2em;
      & .icon {
        margin-top: 0.35em;
        font-size: 1.5em;
      }
    }
  }
}

@mixin Actions--hover-background($bgColor) {
  .Action {
    &:hover {
      background-color: $bgColor;
    }
  }
}

@mixin Actions--hover-opacity {
  .Action {
    &:hover {
      opacity: 0.8;
    }
  }
}
